<template>
  <div>
    <div class="container trans-effect">
      <div class="top-area">
        <div class="top-area-right top-last">
          <div class="icon-cal">
            <b-datepicker
              :week1="[dates.startDate, dates.endDate]"
              :week2="[dates.compareStartDate, dates.compareEndDate]"
              :io-options="ioOptions"
              @change="bDatepickerChanges"
            />
          </div>
        </div>
      </div>
      <div id="embed"></div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import {
  AuthType,
  AuthFailureType,
  AuthStatus,
  Action,
  init,
  LiveboardEmbed,
  EmbedEvent,
  RuntimeFilterOp,
} from '@thoughtspot/visual-embed-sdk';
import axios from 'axios';
import { clearSessionCookies } from 'adready-api/helpers/cookie-session-helper';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import {
  DATEPICKER_RANGE_OPTIONS,
  DATEPICKER_COMPARE_RANGE_OPTIONS,
  THOUGHTSPOT_BASE_API_URL,
} from '~/constant';

import { eBus } from '~/main';
import config from '~/config';
import { convertEpochToNYTimezone } from '~/util/utility-functions';

export default {
  components: {
    BDatepicker: () =>
      import(/* webpackChunkName: "b-datepicker" */ '~/components/elements/b-datepicker.vue'),
  },
  mixins: [fetchIosMethodMixin, gaEventsMixin],
  computed: {
    ioOptions: get('dashboard/filters@ioOptions'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    dashboardLoading: get('common/dashboardLoading'),
  },
  created() {
    eBus.$on('on-select-advertiser', this.onSelectAdvertiser);
  },
  beforeDestroy() {
    eBus.$off('on-select-advertiser', this.onSelectAdvertiser);
  },
  async mounted() {
    if (!this.account || !this.advertiser) {
      return;
    }
    try {
      if (!this.ioOptions.length) {
        await this.fetchIos();
      }
    } catch (err) {
      console.error('error mounting embedded dashboard component ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }

    await this.initializeDashboard();
  },
  methods: {
    fireLogoutGAEvent() {
      if (config.gaEnabled()) {
        const gaEv = {};
        this.fireGAEvent('flp_auth_logout', gaEv, false);
      }
    },
    async logout() {
      this.fireLogoutGAEvent();
      clearSessionCookies();
      await axios.delete(`${config.ADREADY_URL}/api/token/remove`, { withCredentials: true });
      console.log(`Redirecting to ${config.FLIP_URL}`);
      window.location = config.FLIP_URL;
    },
    async initializeDashboard() {
      const response = await axios.get(`${config.ADREADY_URL}/api/token/thoughtSpot`, {
        withCredentials: true,
      });
      const { userName, authToken } = response?.data?.result;

      const authStatus = init({
        thoughtSpotHost: THOUGHTSPOT_BASE_API_URL,
        authType: AuthType.TrustedAuthToken,
        getAuthToken: () => {
          return new Promise((resolve) => {
            resolve(authToken);
          });
        },
        username: userName,
      });
      authStatus.on(AuthStatus.FAILURE, (reason) => {
        if (reason === AuthFailureType.NO_COOKIE_ACCESS) {
          console.log('Third party cookies are blocked. Unable to show the TS liveboard.');
        } else {
          console.log('Ts SDK init failed', reason);
        }
      });
      authStatus.on(AuthStatus.SDK_SUCCESS, () => {
        this.embedDashboard();
      });
    },
    embedDashboard() {
      const fromDate = Math.round(this.dates.startDate.getTime() / 1000);
      const toDate = Math.round(this.dates.endDate.getTime() / 1000);
      const embed = new LiveboardEmbed('#embed', {
        frameParams: {},
        visibleActions: [Action.DownloadAsPdf],
        liveboardId: 'dc227121-be91-498e-a63e-d426e0de0ba0',
        runtimeFilters: [
          {
            columnName: 'Advertiser',
            operator: RuntimeFilterOp.EQ,
            values: [this.advertiser.name],
          },
          {
            columnName: 'Date ET',
            operator: RuntimeFilterOp.BW_INC,
            values: [fromDate, toDate],
          },
        ],
      });
      embed.on(EmbedEvent.AuthExpire, () => {
        this.logout();
      });
      embed.render();
    },
    onSelectAdvertiser() {
      this.embedDashboard();
    },
    bDatepickerChanges(data) {
      this.$store.set(`common/RESET_CONVERSION_WINDOW`);
      const datePickerPayload = {
        startDate: convertEpochToNYTimezone(data.dates[0]),
        endDate: convertEpochToNYTimezone(data.dates[1]),
        compareWith: data.compareWith,
        compareStartDate: convertEpochToNYTimezone(data.dates[2]),
        compareEndDate: convertEpochToNYTimezone(data.dates[3]),
        dateRangeOption: data.dateRangeOption,
        dateCompareOption: data.dateCompareOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      this.datePickerChangedGAEvent(datePickerPayload);
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$forceUpdate();
      this.embedDashboard();
    },
    datePickerChangedGAEvent(datePickerPayload) {
      if (config.gaEnabled()) {
        const {
          startDate,
          endDate,
          compareWith,
          compareStartDate,
          compareEndDate,
          dateRangeOption,
          dateCompareOption,
        } = datePickerPayload;

        const gaEv = {
          start_date: startDate,
          end_date: endDate,
          compare: compareWith,
          date_range_option: DATEPICKER_RANGE_OPTIONS[`${dateRangeOption}`],
        };

        if (compareWith) {
          gaEv.compare_start_date = compareStartDate;
          gaEv.compare_end_date = compareEndDate;
          gaEv.compare_date_option = DATEPICKER_COMPARE_RANGE_OPTIONS[`${dateCompareOption}`];
        }
        this.fireGAEvent('flp_dtpk_change', gaEv);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-area {
  margin-bottom: 15px;
  color: #fff;
}

.icon-cal {
  position: relative;
  top: -1px;
  display: inline-flex;
  float: right;
  right: 20px;
}

.top-last {
  margin-top: 0px;
}
.container {
  max-width: none;
  padding: 40px 0;
}
.container > * {
  max-width: none;
}
.top-area {
  height: 60px;
  background-color: #e8edf2;
  box-shadow: 0px 5px 20px rgb(0 40 50 / 10%);
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  .top-area-right {
    max-width: 1373px;
    width: 100%;
    padding: 5px 30px;
    margin: auto;
  }
}
::v-deep .drp-wrap > input {
  color: #545861;
  font-size: 1.13em;
  letter-spacing: 0.03em;
}
::v-deep .drp-wrap .compare-label {
  font-size: 75%;
  color: rgba(129, 140, 165, 1);
  font-family: 'Manrope', sans-serif;
}
</style>
<style lang="scss">
#embed {
  height: 100vh;
  background-color: #e5e7eb;
  // margin-top: -65px;
}
.ss-header {
  background-color: #2c2e38 !important;
}
</style>
